// import

import type {BlockProps} from './Block';

import {css} from '@linaria/core';

import {Link} from 'modules/Link';
import {Image, Video} from 'modules/Media';

import {Embed} from './Embed';
import {Viewport} from './Viewport';
import {getColorPair} from './utils';

// types

type Props = Omit<BlockProps<'textMedia'>, 'firstInRegion' | 'firstOfType' | 'id' | 'lastInRegion' | 'lastOfType' | 'title' | 'type'> & {
  title?: string;
  smallVideo?: boolean;
};

// component

export function MediaBlock(props: Props) {
  const {embedMeta, imageMeta, layout, link, action, embed, image, title, align, backdrop, smallVideo} = props;
  const src = image?.[0];
  const isVideo = src?.includes('/video/upload/');

  let mediaEl = embed && embedMeta ? (
    <Embed embedMeta={embedMeta} />
  ) : src && isVideo ? (
    <Video
      autoPlay
      loop
      muted
      className={mediaCls}
      maxWidth={smallVideo ? 578 : 1008}
      src={src} />
  ) : src && imageMeta ? (
    <Image
      alt={link ? action || title : title}
      className={mediaCls}
      height={imageMeta.height}
      layout="raw"
      src={imageMeta.url}
      width={imageMeta.width} />
  ) : null;

  if (!mediaEl) {
    return null;
  }

  if (link && !embedMeta) {
    mediaEl = (
      <Link href={link}>
        {mediaEl}
      </Link>
    );
  }

  if (layout === 'viewport') {
    mediaEl = (
      <Viewport align={align} color={getColorPair(backdrop)}>
        {mediaEl}
      </Viewport>
    );
  }

  return mediaEl;
}

// styles

const mediaCls = css`
display: block !important;
width: 100%;
height: auto;

* { display: block !important; }
`;
