// import

import type {DisplayVal} from './utils';
import type {BackdropKey} from '@arc/hub-model';
import type {ComponentProps} from 'react';

import {css} from '@linaria/core';

import {cram} from '@arc/rambo';

import {Link} from 'modules/Link';

import {getColorPair} from './utils';

// types

type Props = {
  display: 'border' | 'solid' | 'text';
  color: BackdropKey;
};

// component

export function ActionLink(props: ComponentProps<typeof Link> & Props) {
  const {children, className, style, display, color, ...rest} = props;

  return (
    <Link
      {...rest}
      className={cram(displays[display], className)}
      style={{
        ...style,
        '--link': `var(--${getColorPair(color)}-35)`,
        '--button': `var(--${getColorPair(color)}-40)`,
      }}>
      <div className={backCls} />
      <div className={frontCls}>{children}</div>
    </Link>
  );
}

export function ActionButton(props: ComponentProps<'button'> & Props) {
  const {children, className, style, display, color, ...rest} = props;

  return (
    <button
      type="button"
      {...rest}
      className={cram(actCls, displays[display], className)}
      style={{
        ...style,
        '--link': `var(--${getColorPair(color)}-35)`,
        '--button': `var(--${getColorPair(color)}-40)`,
      }}>
      <div className={cram(backCls, className)} />
      <div className={frontCls}>{children}</div>
    </button>
  );
}

// styles

const actCls = css`
all: unset;
cursor: pointer;
`;

const buttonCls = css`
padding: 0.75rem 2rem;
font-weight: 400;
letter-spacing: normal;
position: relative;
border-radius: 0.25rem;
`;

const backCls = css`
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
border-radius: 0.25rem;
transition: filter 100ms ease-in-out;
`;

const frontCls = css`
position: relative;
z-index: 1;
`;

const solidCls = css`
color: var(--white);

.${backCls} { background: var(--button); }

&:hover {
  .${backCls} { filter: brightness(60%) saturate(150%); }
}
`;

const borderCls = css`
color: var(--button);
background: var(--white);

.${backCls} {
  border: 0.125rem solid var(--button);
  filter: brightness(175%) saturate(50%);
}

&:hover {
  .${backCls} { filter: brightness(100%) saturate(100%); }
}
`;

const textCls = css`
font-weight: 400;
font-variation-settings: 'wght' 450;
transition: filter 100ms ease-in-out;
color: var(--link);

.${backCls} { display: none; }
&:hover { filter: brightness(60%) saturate(150%); }
`;

const displays: Record<DisplayVal, string> = {
  text: textCls,
  solid: cram(buttonCls, solidCls),
  border: cram(buttonCls, borderCls),
};
