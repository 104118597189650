// import

import type {BlockProps} from '../styles/Block';

import {css} from '@linaria/core';

import {cram} from '@arc/rambo';

import {Image} from 'modules/Media';

import {Divider, getDividerHeight} from '../styles/Divider';
import {clamp, getBackdrop, getFlexAlign} from '../styles/utils';

// types

type Props = BlockProps<'region'>;

// component

export function RegionBlock(props: Props) {
  const {
    id, firstOfType,
    backdrop, prevBackdrop = backdrop,
    imageMeta, align, lean, layout,
  } = props;

  if (firstOfType) {
    return (
      <div id={id} />
    );
  }

  return (
    <div
      className={regionCls}
      id={id}
      style={{
        '--height': getDividerHeight(layout),
        '--align': getFlexAlign(align),
        '--flex-above': lean === 'above' ? 3 : 1,
        '--flex-below': lean === 'below' ? 3 : 1,
        '--inline': clamp(1.5, 9, 26.99875, 72.99875),
        '--max': align === 'center' ? 'auto' : '18rem',
      }}>
      <div className={backdropCls}>
        <div
          className={cram(colorCls, aboveCls)}
          style={{'--backdrop': getBackdrop(prevBackdrop)}} />

        <Divider
          className={dividerCls}
          colorAbove={prevBackdrop}
          colorBelow={backdrop}
          id={id}
          layout={layout} />

        <div
          className={cram(colorCls, belowCls)}
          style={{'--backdrop': getBackdrop(backdrop)}} />
      </div>

      {imageMeta ? (
        <div className={innerCls}>
          <div className={mediaCls}>
            <Image
              priority
              height={imageMeta.height}
              layout="intrinsic"
              objectFit="contain"
              objectPosition="center"
              src={imageMeta.url}
              width={imageMeta.width} />
          </div>
        </div>
      ) : null}
    </div>
  );
}

// styles

const regionCls = css`
position: relative;
min-height: var(--height);
display: flex;
justify-content: center;
align-items: center;
scroll-margin-top: calc(var(--layout-headerHeight) + (var(--block) / 2));
`;

const backdropCls = css`
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
display: flex;
flex-flow: column;
`;

const colorCls = css`
background: var(--backdrop);
`;

const aboveCls = css`
flex: var(--flex-above) 1 auto;
`;

const belowCls = css`
flex: var(--flex-below) 1 auto;
`;

const dividerCls = css``;

const innerCls = css`
flex: 1 1 auto;
margin-inline: var(--inline);
max-width: var(--bp-ml);
display: flex;
justify-content: var(--align);
`;

const mediaCls = css`
flex: 0 1 auto;
min-width: 9rem;
max-width: var(--max);
height: auto;
`;
