// import

import type {BlockProps} from '../styles/Block';

import {css} from '@linaria/core';

import {ContactForm} from 'modules/Contact';

import {Block, pickStyleProps} from '../styles/Block';

// types

type Props = BlockProps<'unique'>;

// component

export function UniqueBlock(props: Props) {
  const {block} = props;

  return (
    <Block {...pickStyleProps(props)} innerClassName={innerCls}>
      {block === 'contactForm' ? (
        <ContactForm className={formCls} />
      ) : null}
    </Block>
  );
}

// styles

const innerCls = css`
display: flex;
justify-content: center;
`;

const formCls = css`
max-width: var(--bp-s);
width: 100%;
`;
