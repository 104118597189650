// import

import type {BackdropKey} from '@arc/hub-model';
import type {ComponentProps} from 'react';

import {css} from '@linaria/core';

import {cram} from '@arc/rambo';

import {getBackdrop} from './utils';

// types

type Props = ComponentProps<'div'> & {
  color?: BackdropKey;
};

// component

export function Plaque(props: Props) {
  const {children, className, color = 'yellow'} = props;

  return (
    <div
      className={cram(plaqueCls, className)}
      style={{
        '--c0': getBackdrop(color),
        '--c1': `var(--${color}-80)`,
        '--c2': `var(--${color}-70)`,
        '--c3': `var(--${color}-60)`,
      }}>
      <div>
        <div className={cram(lipCls, lip1Cls)} />
        <div className={cram(lipCls, lip2Cls)} />
        <div className={cram(lipCls, lip3Cls)} />
      </div>

      <div className={contentCls}>
        {children}
      </div>

      <div className={flipCls}>
        <div className={cram(lipCls, lip1Cls)} />
        <div className={cram(lipCls, lip2Cls)} />
        <div className={cram(lipCls, lip3Cls)} />
      </div>
    </div>
  );
}

// styles

const plaqueCls = css`
width: 100%;
padding: 0 0.75rem;
filter:
  drop-shadow(0.75rem 0 var(--c1))
  drop-shadow(-0.75rem 0 var(--c1))
  drop-shadow(0.5rem 0 var(--c2))
  drop-shadow(-0.5rem 0 var(--c2))
  drop-shadow(0.25rem 0 var(--c3))
  drop-shadow(-0.25rem 0 var(--c3));
`;

const flipCls = css`
transform: rotate(180deg);
`;

const lipCls = css`
margin: 0 auto;
position: relative;
background-color: var(--c0);
height: 1.5rem;
border-radius: 1.5rem 1.5rem 0 0;
`;

const lip1Cls = css`
width: 50%;
`;

const lip2Cls = css`
width: 75%;
`;

const lip3Cls = css`
width: 100%;
`;

const contentCls = css`
background-color: var(--c0);
padding: 0.75rem 1.5rem 0;
text-align: center;
`;
