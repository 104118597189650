// import

import type {BlockProps} from '../styles/Block';

import {NodeLinksList} from '../styles/NodeLinksList';

// types

type Props = BlockProps<'supportLinks'>;

// component

export function SupportLinksBlock(props: Props) {
  return (
    <NodeLinksList {...props} />
  );
}
