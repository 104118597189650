// import

import type {PersonNode} from '@arc/hub-model';
import type {ComponentProps} from 'react';

import {faGlobe, faNewspaper} from '@fortawesome/pro-regular-svg-icons';
import {styled} from '@linaria/react';

import {Icon} from 'modules/Icon';
import {Link} from 'modules/Link';
import {Image} from 'modules/Media';
import {Type} from 'modules/Type';

// types

type Props = ComponentProps<typeof Styled> & {
  person?: PersonNode;
  accent?: string;
};

// component

export function PostAuthor(props: Props) {
  const {person, style, accent = 'var(--blue)', ...rest} = props;

  return person ? (
    <Styled {...rest} style={{...style, '--accent': accent}}>
      <div className="media">
        <Image
          alt={`Image of ${person.meta.title}`}
          className="image"
          height={250}
          layout="intrinsic"
          src={person.meta.photoImage}
          width={250} />
      </div>

      <div className="text">
        <header>{person.meta.title}</header>

        <Type className="body">
          {person.body}
        </Type>

        <footer>
          {person.meta.role ? (
            <div>{person.meta.role}</div>
          ) : null}

          {person.meta.website ? (
            <Link href={person.meta.website}>
              <Icon icon={faGlobe} />&thinsp;
              <span>Website</span>
            </Link>
          ) : null}

          {person.meta.hasNodes ? (
            <Link href={person.meta.link}>
              <Icon icon={faNewspaper} />&thinsp;
              <span>Column</span>
            </Link>
          ) : null}
        </footer>
      </div>
    </Styled>
  ) : null;
}

// styles

const Styled = styled('aside')`
display: flex;
gap: 1.5rem;
align-items: flex-start;

.media {
  flex: 1 1 25%;
  box-shadow: 0 0 0.5rem var(--black-25);
  border-radius: 0.25rem;
  overflow: hidden;

  > * { display: block !important; }
}
.text { flex: 1 1 75%; }

.image {
  height: 100%;
  width: auto;
}

header {
  margin-bottom: 0.5rem;
  font-family: var(--font-head);
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: normal;
}

.body {
  font-size: 1.125rem;
  line-height: 1.75rem;

  > * {
    word-wrap: normal !important;
    hyphens: manual !important;
  }

  a {
    transition: color 100ms ease-in-out;
    text-decoration: underline var(--accent) !important;
    &:hover { color: var(--accent) !important; }
  }
}

footer {
  margin-top: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  gap: 0.75rem;

  a {
    transition: color 100ms ease-in-out;
    &:hover { color: var(--accent); }
  }
}

svg {
  width: 1rem;
  position: relative;
  top: 0.125rem;
  margin-right: var(--rpx);
}
`;
