// import

import type {BlockProps} from '../styles/Block';

import {css} from '@linaria/core';

import {cram} from '@arc/rambo';

import {ActionFields} from '../styles/ActionFields';
import {Block, pickStyleProps} from '../styles/Block';
import {MediaBlock} from '../styles/MediaBlock';
import {TextFields} from '../styles/TextFields';

// types

type Props = BlockProps<'textMedia'>;

// vars

const lr = ['left', 'right'];

// component

export function TextMediaBlock(props: Props) {
  const {align, imageMeta, embedMeta} = props;
  const {layout, ...rest} = props;
  const isLR = (embedMeta || imageMeta) && lr.includes(align);
  const textAlign = isLR ? 'left' : 'center';

  return (
    <Block
      {...pickStyleProps(props)}
      innerClassName={cram(innerCls, {[abCls]: !isLR})}>
      <TextFields {...rest} align={textAlign} className={textCls} inc={1}>
        <ActionFields {...rest} align={textAlign} />
      </TextFields>

      <div
        className={cram(mediaCls, {
          [leadCls]: ['above', 'left'].includes(align),
          [lrCls]: isLR,
          [viewportCls]: isLR && layout === 'viewport',
        })}>
        <MediaBlock {...props} smallVideo={isLR} />
      </div>
    </Block>
  );
}

// styles

const innerCls = css`
display: flex;
gap: 3rem;
align-items: center;
justify-content: center;

@media (max-width: 53.99875em) {
  flex-flow: wrap;
}
`;

const abCls = css`
flex-flow: column;
justify-content: stretch;
`;

const textCls = css`
flex: 2 1 fill;
min-width: 18rem;
max-width: var(--bp-sm);
`;

const mediaCls = css`
flex: 1 2 content;
min-width: 18rem;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
`;

const leadCls = css`
order: -1;
`;

const lrCls = css`
max-width: 36rem;

@media (max-width: 53.99875em) {
  order: 10;
}
`;

const viewportCls = css`
flex: 2 1 content;
min-width: 31.5rem;
max-width: 45rem;

@media (max-width: 53.99875em) {
  min-width: 18rem;
}
`;
