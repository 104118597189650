// import

import type {PageNode} from '@arc/hub-model';

import {css} from '@linaria/core';

import {PageSeo} from './PageSeo';
import {useBuilder} from './useBuilder';

// types

type Props = {
  page: PageNode;
};

// component

export function PagePage(props: Props) {
  const {page} = props;

  return (
    <article className={articleCls}>
      <PageSeo page={page} />
      {useBuilder(page.meta.builder)}
    </article>
  );
}

// styles

const articleCls = css``;
