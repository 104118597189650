// import

import type {BlockComponent} from './styles/Block';
import type {BuilderBlock, RegionBlock as RegBlock} from '@arc/hub-model';

import * as R from 'ramda';
import {useMemo} from 'react';

import {AccordionBlock} from './blocks/AccordionBlock';
import {ActionBlock} from './blocks/ActionBlock';
import {BreakBlock} from './blocks/BreakBlock';
import {CollectionBlock} from './blocks/CollectionBlock';
import {FaqsBlock} from './blocks/FaqsBlock';
import {HeadlineBlock} from './blocks/HeadlineBlock';
import {PeopleBlock} from './blocks/PeopleBlock';
import {PostLinksBlock} from './blocks/PostLinksBlock';
import {PullQuoteBlock} from './blocks/PullQuoteBlock';
import {RegionBlock} from './blocks/RegionBlock';
import {RichTextBlock} from './blocks/RichTextBlock';
import {SupportLinksBlock} from './blocks/SupportLinksBlock';
import {TextMediaBlock} from './blocks/TextMediaBlock';
import {UniqueBlock} from './blocks/UniqueBlock';
import {ViewportBlock} from './blocks/ViewportBlock';

// config

const blockMap = {
  region: RegionBlock,
  break: BreakBlock,

  headline: HeadlineBlock,
  textMedia: TextMediaBlock,
  action: ActionBlock,

  collection: CollectionBlock,
  accordion: AccordionBlock,

  richText: RichTextBlock,
  pullQuote: PullQuoteBlock,

  faqs: FaqsBlock,
  people: PeopleBlock,

  postLinks: PostLinksBlock,
  supportLinks: SupportLinksBlock,

  viewport: ViewportBlock,
  unique: UniqueBlock,
} as const;

// vars

const start: RegBlock = {
  id: 'start',
  type: 'region',
  backdrop: 'red',
  layout: 'none',
  align: 'center',
  lean: 'center',
};

// hook

export function useBuilder(blocks: BuilderBlock[]) {
  return useMemo(() => {
    const parsed = blocks.reduce<BuilderBlock[]>((acc, block, i) => {
      const prev = acc[acc.length - 1] ? acc[acc.length - 1] : undefined;
      const next = blocks[i + 1] ? blocks[i + 1] : undefined;

      if (block.type === 'region') {
        if (next && prev?.type !== 'region') {
          acc.push(block);
        }
      } else {
        if (!prev) {
          acc.push(start);
        }

        acc.push(block);
      }

      return acc;
    }, []);

    let prevRegion: RegBlock;
    let thisRegion: RegBlock;
    const [regions, other] = R.partition((b) => b.type === 'region', parsed);
    const firsts = [regions[0], other[0]];
    const lasts = [regions[regions.length - 1], other[other.length - 1]];

    return parsed.map((block, key) => {
      const above = blocks[key - 1] ? blocks[key - 1] : undefined;
      const below = blocks[key + 1] ? blocks[key + 1] : undefined;
      const El = blockMap[block.type] as BlockComponent;

      if (block.type === 'region') {
        prevRegion = thisRegion;
        thisRegion = block;
      }

      return (
        <El
          key={key}
          {...block}
          backdrop={thisRegion.backdrop}
          firstInRegion={above && above.type === 'region'}
          firstOfType={firsts.includes(block)}
          lastInRegion={!below || below.type === 'region'}
          lastOfType={lasts.includes(block)}
          prevBackdrop={prevRegion?.backdrop} />
      );
    });
  }, [blocks]);
}
