// import

import type {BlockProps} from '../styles/Block';

import {faXmark} from '@fortawesome/pro-regular-svg-icons';
import {css} from '@linaria/core';
import {Accordion, AccordionItem, AccordionButton, AccordionPanel} from '@reach/accordion';

import {Icon} from 'modules/Icon';

import {Block, pickStyleProps} from '../styles/Block';
import {Blurb} from '../styles/Blurb';
import {TextFields} from '../styles/TextFields';

// types

type Props = BlockProps<'faqs'>;

// component

export function FaqsBlock(props: Props) {
  const {faqRefs, title, backdrop} = props;
  const border = backdrop === 'white' ?
    'var(--gray-75)' :
    `var(--${backdrop}-35)`;
  const hover = backdrop === 'white' ?
    'var(--red)' :
    `var(--${backdrop}-35)`;

  return (
    <Block {...pickStyleProps(props)} innerClassName={innerCls}>
      {title ? (
        <TextFields
          {...props}
          backdrop={backdrop}
          inc={1}
          layout="bold"
          title={title} />
      ) : null}

      <Accordion
        collapsible
        multiple
        className={listCls}
        style={{
          '--border': border,
          '--hover': hover,
        }}>
        {faqRefs.map((faq) => (
          <AccordionItem key={faq.meta.id} as="article" className={itemCls}>
            <header>
              <AccordionButton className={buttonCls}>
                <div className={textCls}>{faq.meta.title}</div>
                <Icon className={iconCls} icon={faXmark} />
              </AccordionButton>
            </header>

            <AccordionPanel className={bodyCls}>
              <Blurb color={backdrop}>
                {faq.body}
              </Blurb>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Block>
  );
}

// styles

const innerCls = css`
display: flex;
flex-flow: column;
gap: 3rem;
align-items: center;
`;

const listCls = css`
display: flex;
gap: 1.5rem;
flex-flow: column;
width: min(100%, var(--bp-sm));
`;

const itemCls = css`
border-block: var(--rpx) solid var(--border);
`;

const textCls = css`
flex: 1 1 auto;
`;

const iconCls = css`
flex: 0 0 auto;
transform: rotate(-45deg);
transition: transform 0.125s ease-in-out;
`;

const buttonCls = css`
all: unset;
box-sizing: border-box;
background: none;
border: none;
display: flex;
align-items: center;
width: 100%;
gap: 1rem;

padding: 1.5rem 1rem;
user-select: none;
cursor: pointer;

font-family: var(--font-body);
font-size: 1.5rem;
font-weight: 500;
font-feature-settings: "wght" 500;
text-align: left;

transition: color 0.125s ease-in-out;

&:hover { color: var(--hover); }

&[aria-expanded="true"] .${iconCls} {
  transform: rotate(0);
}
`;

const bodyCls = css`
padding: 0 1rem 1.5rem;
`;
