// import

import type {HeadEls} from './utils';
import type {ComponentProps} from 'react';

import {css} from '@linaria/core';

import {cram} from '@arc/rambo';

import {getAlign} from './Title';

// types

type Props = ComponentProps<'div'> & {
  as?: HeadEls;
  align?: 'center' | 'left' | 'right';
  children: string;
};

// component

export function Eyebrow(props: Props) {
  const {
    children, className, style,
    as: El = 'div', align,
    ...rest
  } = props;

  return (
    <El
      {...rest}
      className={cram(browCls, className)}
      dangerouslySetInnerHTML={{__html: children}}
      style={{
        ...style,
        '--align': align ?? getAlign(children),
      }} />
  );
}

// styles

const browCls = css`
margin: 0;
text-align: var(--align);
text-transform: uppercase;
font-family: var(--font-body);
letter-spacing: normal;
font-weight: 500;
font-size: 1rem;
line-height: 1.25rem;
`;
