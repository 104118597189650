// import

import type {BlockProps} from '../styles/Block';

import {faChevronDown} from '@fortawesome/pro-regular-svg-icons';
import {css} from '@linaria/core';
import {Accordion, AccordionItem, AccordionButton, AccordionPanel} from '@reach/accordion';
import {Tabs, TabPanels, TabPanel} from '@reach/tabs';
import {useState} from 'react';

import {cram} from '@arc/rambo';

import {Icon} from 'modules/Icon';
import {Image} from 'modules/Media';
import {useRouteCapture} from 'modules/Router';

import {ActionFields} from '../styles/ActionFields';
import {Block, pickStyleProps} from '../styles/Block';
import {Blurb} from '../styles/Blurb';
import {TextFields} from '../styles/TextFields';

// types

type Props = BlockProps<'accordion'>;

// fns

const noop = () => null;

// component

export function AccordionBlock(props: Props) {
  const {backdrop, eyebrow, items, title} = props;
  const [index, setIndex] = useState(0);
  const ref = useRouteCapture();
  const border = backdrop === 'white' ?
    'var(--gray-75)' :
    `var(--${backdrop}-35)`;
  const hover = backdrop === 'white' ?
    'var(--red)' :
    `var(--${backdrop}-35)`;

  return (
    <Block {...pickStyleProps(props)} innerClassName={innerCls}>
      <div className={cram(blurbCls, {[browCls]: !!eyebrow})}>
        <TextFields {...props} align="left" inc={2} title={title} />

        <Accordion
          ref={ref as any}
          collapsible
          index={index}
          style={{
            '--border': border,
            '--hover': hover,
          }}
          // eslint-disable-next-line react/jsx-no-bind
          onChange={(v) => setIndex(v ?? 0)}>
          {items.map((item, key) => (
            <AccordionItem key={key} as="article" className={itemCls}>
              <header>
                <AccordionButton className={buttonCls}>
                  <Icon className={iconCls} icon={faChevronDown} />
                  <div className={textCls}>{item.title}</div>
                </AccordionButton>
              </header>

              <AccordionPanel className={bodyCls}>
                <Blurb color={backdrop}>
                  {item.blurbMark}
                </Blurb>

                <ActionFields {...item} backdrop={backdrop} />
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>

      <Tabs className={flexCls} index={index} onChange={noop}>
        <TabPanels className={flexCls}>
          {items.map((item, key) => (
            <TabPanel key={key} className={mediaCls}>
              <Image
                priority
                height={item.imageMeta.height}
                layout="intrinsic"
                objectFit="contain"
                objectPosition="center"
                src={item.imageMeta.url}
                width={item.imageMeta.width} />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Block>
  );
}

// styles

const innerCls = css`
display: grid;
gap: 1.5rem;
grid-template-columns: 1fr 1fr;
grid-auto-rows: auto;
align-items: center;
justify-items: center;
max-width: var(--bp-l);

@media (max-width: 53.99875em) {
  grid-template-columns: 1fr;
}
`;

const blurbCls = css`
display: flex;
flex-flow: column;
width: 100%;
gap: 1.5rem;
`;

const browCls = css`
margin-top: -1.25rem;
`;

const flexCls = css`
display: flex;
align-items: center;
justify-content: center;
max-width: 27rem;
`;

const mediaCls = css`
> * { display: block !important; }
`;

const itemCls = css`
&:nth-child(n + 2) {
  border-top: var(--rpx) solid var(--border);
}
`;

const textCls = css`
flex: 1 1 auto;
`;

const iconCls = css`
flex: 0 0 auto;
transform: rotate(-90deg);
transition: transform 100ms ease-in-out;
font-size: 1.5rem;
`;

const buttonCls = css`
all: unset;
box-sizing: border-box;
background: none;
border: none;
display: flex;
align-items: center;
width: 100%;
gap: 1rem;

padding: 1.25rem 0.75rem;
user-select: none;
cursor: pointer;

font-family: var(--font-body);
font-size: 1.5rem;
font-weight: 500;
font-feature-settings: "wght" 500;
text-align: left;

transition: color 100ms ease-in-out;

&:hover { color: var(--hover); }

&[aria-expanded="true"] .${iconCls} {
  transform: rotate(0);
}
`;

const bodyCls = css`
padding: 0 0.75rem 1.25rem 1.5rem;
flex-flow: column;
width: 100%;
gap: 1.5rem;

&[aria-expanded="true"] {
  display: flex;
}
`;
