// import

import type {BlockProps} from './Block';

import {faArrowRight} from '@fortawesome/pro-regular-svg-icons';
import {css} from '@linaria/core';

import {cram} from '@arc/rambo';

import {Icon} from 'modules/Icon';
import {Link} from 'modules/Link';

import {Block, pickStyleProps} from './Block';
import {TextFields} from './TextFields';
import {getColorPair} from './utils';

// types

type Props = BlockProps<'postLinks' | 'supportLinks'>;

// component

export function NodeLinksList(props: Props) {
  const {backdrop, eyebrow, title} = props;
  // @ts-expect-error on postLinks
  const {layout, ...textProps} = props;
  // eslint-disable-next-line react/destructuring-assignment
  const refs = 'supportRefs' in props ? props.supportRefs : props.postRefs;
  const border = backdrop === 'white' ?
    'var(--gray-75)' :
    `var(--${backdrop}-35)`;

  return (
    <Block
      {...pickStyleProps(props)}
      innerClassName={innerCls}
      style={{
        '--border': border,
      }}>
      {title ? (
        <TextFields
          {...textProps}
          align="left"
          className={cram(blurbCls, {[browCls]: !!eyebrow})}
          inc={2}
          title={title} />
      ) : null}

      <nav
        className={linksCls}
        style={{
          '--color': `var(--${getColorPair(backdrop)}-35)`,
        }}>
        {refs.map((node) => (
          <Link
            key={node.meta.id}
            className={linkCls}
            href={node.meta.link}>
            <div className={textCls}>
              {node.meta.title}
            </div>

            <Icon
              className={iconCls}
              icon={faArrowRight} />
          </Link>
        ))}
      </nav>
    </Block>
  );
}

// styles

const innerCls = css`
display: flex;
gap: 1.5rem;

@media (max-width: 53.99875em) {
  flex-flow: column;
}
`;

const blurbCls = css`
flex: 1 1 auto;
min-width: 45%;
`;

const browCls = css`
margin-top: -1.25rem;
`;

const linksCls = css`
flex: 1 1 auto;
min-width: 45%;
`;

const textCls = css`
flex: 1 1 auto;
transition: color 250ms ease-in-out;
`;

const iconCls = css`
flex: 0 0 auto;
width: 1rem;
height: 1rem;
margin-inline: 0.5rem;
color: var(--color);
transition: transform 250ms ease-in-out;
`;

const linkCls = css`
display: flex;
align-items: center;
gap: 1.5rem;
padding-block: 0.25rem;

&:nth-child(n + 2) {  
  border-top: var(--rpx) solid var(--border);
}

&:hover {
  .${textCls} { color: var(--color); }
  .${iconCls} { transform: translateX(1rem); }
}
`;
