// import

import type {BlockProps} from '../styles/Block';

import {css} from '@linaria/core';

import {ActionFields} from '../styles/ActionFields';
import {Block, pickStyleProps} from '../styles/Block';

// types

type Props = BlockProps<'action'>;

// component

export function ActionBlock(props: Props) {
  const {link} = props;

  return (
    <Block {...pickStyleProps(props)}>
      <ActionFields
        align="center"
        className={buttonCls}
        display={link?.includes('app.') ? 'solid' : 'border'}
        {...props} />
    </Block>
  );
}

// styles

const buttonCls = css`
> :first-child { min-width: min(27rem, 75%); }
> a { text-transform: uppercase; }
`;
