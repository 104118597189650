// import

import type {BlockProps} from '../styles/Block';

import {css} from '@linaria/core';

import {PostAuthor} from 'modules/Post/PostAuthor';

import {Block, pickStyleProps} from '../styles/Block';
import {TextFields} from '../styles/TextFields';
import {getColorPair} from '../styles/utils';

// types

type Props = BlockProps<'people'>;

// component

export function PeopleBlock(props: Props) {
  const {backdrop, personRefs, title} = props;

  return (
    <Block {...pickStyleProps(props)} innerClassName={innerCls}>
      {title ? (
        <TextFields {...props} align="left" inc={1} title={title} />
      ) : null}

      <div className={listCls}>
        {personRefs.map((person) => (
          <PostAuthor
            key={person.meta.id}
            accent={`var(--${getColorPair(backdrop)}-50)`}
            className={itemCls}
            person={person} />
        ))}

      </div>
    </Block>
  );
}

// styles

const innerCls = css`
display: flex;
flex-flow: column;
gap: 3rem;
max-width: var(--bp-m);
`;

const listCls = css`
display: grid;
gap: 3rem;
flex-flow: wrap;
align-self: center;
justify-content: flex-start;
`;

const itemCls = css`
flex: 1 1 40%;
`;
