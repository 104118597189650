// import

import type {BlockProps} from '../styles/Block';

import {css} from '@linaria/core';

import {BannerImage} from 'modules/Solo';

import {Block, pickStyleProps} from '../styles/Block';
import {Blurb} from '../styles/Blurb';
import {NodeLinksList} from '../styles/NodeLinksList';
import {TextFields} from '../styles/TextFields';
import {Title5} from '../styles/Title';
import {getColorPair} from '../styles/utils';

// types

type Props = BlockProps<'postLinks'>;

// component

export function PostLinksBlock(props: Props) {
  const {backdrop, options = [], postRefs, title} = props;
  const {layout, ...textProps} = props;
  const color = getColorPair(backdrop);

  if (layout === 'list') {
    return (
      <NodeLinksList {...props} />
    );
  }

  return (
    <Block
      {...pickStyleProps(props)}
      innerClassName={innerCls}
      style={{
        '--cols': postRefs.length <= 3 ?
          postRefs.length : Math.ceil(postRefs.length / 2),
      }}>
      {title ? (
        <TextFields
          {...textProps}
          align="left"
          className={blurbCls}
          inc={1}
          title={title} />
      ) : null}

      <div className={gridCls}>
        {postRefs.map((post) => (
          <article key={post.meta.id} className={itemCls}>
            <BannerImage
              height={250}
              node={post}
              width={500} />

            <Title5 align="left" as="h4" className={textCls} color={color}>
              {`<a href="${post.meta.link}">${post.meta.title}</a>`}
            </Title5>

            {options.includes('desc') ? (
              <Blurb className={descCls}>
                {post.meta.seoDescription}
              </Blurb>
            ) : null}
          </article>
        ))}
      </div>
    </Block>
  );
}

// styles

const innerCls = css`
max-width: var(--bp-l);
margin-inline: calc(var(--inline) / 2);
display: flex;
flex-flow: column;
gap: 3rem;
`;

const blurbCls = css`
flex: 1 1 auto;
`;

const gridCls = css`
display: grid;
grid-template-columns: repeat(var(--cols), 1fr);
gap: 3rem 1.5rem;
justify-content: center;
align-items: start;

@media (max-width: 53.99875em) {
  grid-template-columns: repeat(min(var(--cols), 2), 1fr);
}

@media (max-width: 35.99875em) {
  grid-template-columns: repeat(1, 1fr);
}
`;

const itemCls = css`
display: flex;
flex-flow: column;
gap: 0.75rem;

@media (max-width: 35.99875em) {
  &:nth-child(n + 5) { display: none; }
}
`;

const textCls = css`
a {
  color: inherit;
  &:hover { color: var(--color); }
}
`;

const descCls = css`
font-size: 1rem;
line-height: 1.5rem;
`;
