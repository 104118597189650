// import

import type {BlockProps} from '../styles/Block';

import {ActionFields} from '../styles/ActionFields';
import {Block, pickStyleProps} from '../styles/Block';
import {Marquee} from '../styles/Marquee';
import {Plaque} from '../styles/Plaque';
import {TextFields} from '../styles/TextFields';
import {getColorPair} from '../styles/utils';

// types

type Props = BlockProps<'headline'>;

// component

export function HeadlineBlock(props: Props) {
  const {layout, ...rest} = props;
  const {backdrop} = props;
  const color = backdrop === 'white' ? 'yellow' : getColorPair(backdrop);
  const align = layout === 'subhead' ? 'left' : 'center';

  return layout === 'marquee' ? (
    <Marquee {...rest} color={color}>
      <TextFields {...rest} align={align} backdrop={color}>
        <ActionFields {...rest} align={align} backdrop={color} />
      </TextFields>
    </Marquee>
  ) : (
    <Block {...pickStyleProps(props)}>
      {layout === 'none' || layout === 'bold' || layout === 'subhead' ? (
        <TextFields {...rest} align={align} layout={layout}>
          <ActionFields {...rest} align={align} />
        </TextFields>
      ) : layout === 'plaque' ? (
        <Plaque {...rest} color={color}>
          <TextFields {...rest} align={align} backdrop={color} layout="bold">
            <ActionFields {...rest} align={align} backdrop={color} />
          </TextFields>
        </Plaque>
      ) : null}
    </Block>
  );
}
