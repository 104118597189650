// import

import type {ComponentProps} from 'react';

import {publicCloud} from 'libs/cloudinary';

// types

type Props = ComponentProps<'video'> & {
  src: string;
  maxWidth?: number | string;
};

// component

export function Video(props: Props) {
  const {children, src, maxWidth, width, ...rest} = props;

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video
      {...rest}
      src={publicCloud.updateUrl(src, {
        trans: {
          f: 'auto',
          vc: 'auto',
          q: 'auto:best',
          c: 'limit',
          w: maxWidth,
        },
      })}
      width={width ?? maxWidth}>
      {children}
    </video>
  );
}
