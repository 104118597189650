// import

import type {BlockProps} from '../styles/Block';

import {faDesktop, faMobileButton, faTabletScreenButton} from '@fortawesome/pro-regular-svg-icons';
import {css} from '@linaria/core';
import {Tab, TabList, Tabs, TabPanels, TabPanel} from '@reach/tabs';
import * as R from 'ramda';

import {cram} from '@arc/rambo';

import {Icon} from 'modules/Icon';
import {Image} from 'modules/Media';

import {Block, pickStyleProps} from '../styles/Block';
import {TextFields} from '../styles/TextFields';
import {Viewport} from '../styles/Viewport';
import {getColorPair} from '../styles/utils';

// types

type Props = BlockProps<'viewport'>;

// component

export function ViewportBlock(props: Props) {
  const {align, mobileImage, tabletImage, desktopImage, mobileImageMeta, tabletImageMeta, desktopImageMeta, backdrop} = props;
  const color = getColorPair(backdrop);
  const tabTotal = R.sum([
    mobileImage && mobileImageMeta ? 1 : 0,
    tabletImage && tabletImageMeta ? 1 : 0,
    desktopImage && desktopImageMeta ? 1 : 0,
  ]);

  return (
    <Block {...pickStyleProps(props)} innerClassName={innerCls}>
      <TextFields {...props} align="center" className={textCls} inc={1} />

      <Tabs
        className={cram(mediaCls, {[leadCls]: align === 'above'})}
        defaultIndex={tabTotal - 1}>
        <TabPanels
          align={align}
          as={Viewport}
          color={color}>
          {mobileImage && mobileImageMeta ? (
            <TabPanel className={scrollCls}>
              <Image
                alt="Mobile view"
                height={mobileImageMeta.height}
                layout="intrinsic"
                objectFit="contain"
                objectPosition="center"
                src={mobileImageMeta.url}
                width={mobileImageMeta.width} />
            </TabPanel>
          ) : null}

          {tabletImage && tabletImageMeta ? (
            <TabPanel className={scrollCls}>
              <Image
                alt="Tablet view"
                height={tabletImageMeta.height}
                layout="intrinsic"
                objectFit="contain"
                objectPosition="center"
                src={tabletImageMeta.url}
                width={tabletImageMeta.width} />
            </TabPanel>
          ) : null}

          {desktopImage && desktopImageMeta ? (
            <TabPanel className={scrollCls}>
              <Image
                alt="Desktop view"
                height={desktopImageMeta.height}
                layout="intrinsic"
                objectFit="contain"
                objectPosition="center"
                src={desktopImageMeta.url}
                width={desktopImageMeta.width} />
            </TabPanel>
          ) : null}
        </TabPanels>

        {tabTotal >= 2 ? (
          <TabList
            className={tabsCls}
            style={{
              '--color': `var(--${color})`,
              '--border': `var(--${color}-20)`,
              '--select': `var(--${color}-80)`,
            }}>
            {mobileImage && mobileImageMeta ? (
              <Tab className={tabCls}>
                <div className={washCls} />
                <Icon className={iconCls} icon={faMobileButton} />
              </Tab>
            ) : null}

            {tabletImage && tabletImageMeta ? (
              <Tab className={tabCls}>
                <div className={washCls} />
                <Icon className={iconCls} icon={faTabletScreenButton} />
              </Tab>
            ) : null}

            {desktopImage && desktopImageMeta ? (
              <Tab className={tabCls}>
                <div className={washCls} />
                <Icon className={iconCls} icon={faDesktop} />
              </Tab>
            ) : null}
          </TabList>
        ) : null}
      </Tabs>
    </Block>
  );
}

// styles

const innerCls = css`
display: flex;
gap: 3rem;
align-items: center;
flex-flow: column;
justify-content: stretch;
`;

const textCls = css`
flex: 2 1 fill;
min-width: 18rem;
max-width: var(--bp-sm);
`;

const mediaCls = css`
flex: 1 2 content;
position: relative;
min-width: 18rem;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
`;

const leadCls = css`
order: -1;
`;

const scrollCls = css`
overflow-y: scroll;
max-height: calc(80vh - var(--layout-headerHeight));
min-height: var(--bp-xxs);

* { display: block !important; }
`;

const tabsCls = css`
position: absolute;
bottom: -1.5rem;
height: 3rem;
display: flex;
justify-content: center;
`;

const tabCls = css`
position: relative;
width: 4.5rem;
height: 3rem;
display: flex;
justify-content: center;
align-items: center;
font-size: 1.5rem;
background: var(--color);
border: var(--rpx) solid var(--border);
box-shadow: 0.25rem 0.25rem var(--border);
overflow: hidden;
transition: background 125ms ease-in-out;

&:first-child { 
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}
&:last-child {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
&:nth-child(n + 2) { border-left-width: 0; }

&[data-selected] { background: var(--select); }
&:hover { background: var(--white); }
`;

const washCls = css`
background-color: var(--white-25);
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
`;

const iconCls = css`
position: relative;
z-index: 1;
`;
