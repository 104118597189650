// import

import type {EmbedMeta} from '@arc/hub-model';
import type {ComponentProps} from 'react';

import {css} from '@linaria/core';
import * as R from 'ramda';

import {cram} from '@arc/rambo';

import {Image} from 'modules/Media';

// types

type Props = ComponentProps<'div'> & {
  embedMeta: EmbedMeta;
};

type SizedEmbed = Extract<EmbedMeta, {width: number | string}>;

// fns

const getAspect = (
  meta: SizedEmbed,
  key: keyof SizedEmbed,
): number | null => {
  const raw: number | string = R.propOr(null, key, meta);

  if (!raw) {
    return null;
  }

  const parsed = parseInt(raw as string);

  if (!parsed) {
    return null;
  }

  if (raw.toString() !== parsed.toString()) {
    return null;
  }

  return parsed;
};

const getRatio = (
  meta: SizedEmbed,
  w: keyof SizedEmbed = 'width',
  h: keyof SizedEmbed = 'height',
) => {
  const width = getAspect(meta, w);
  const height = getAspect(meta, h);

  return width && height ? `${width} / ${height}` : '1 / 1';
};

// component

export function Embed(props: Props) {
  const {children, className, embedMeta, ...rest} = props;

  if (embedMeta.type === 'link') {
    throw new Error('Embed type "link" not supported.');
  }

  return 'html' in embedMeta ? (
    <div
      {...rest}
      className={cram(htmlCls, className)}
      dangerouslySetInnerHTML={{__html: embedMeta.html}}
      style={{'--ratio': getRatio(embedMeta)}} />
  ) : embedMeta.type === 'photo' ? (
    <Image
      unoptimized
      height={embedMeta.height}
      layout="intrinsic"
      objectFit="contain"
      objectPosition="center"
      src={embedMeta.url}
      width={embedMeta.width} />
  ) : null;
}

// styles

const htmlCls = css`
width: 100%;
position: relative;

iframe {
  display: block;
  aspect-ratio: var(--ratio);
  width: 100% !important;
  height: auto !important;
}
`;
