// import

import type {AlignVal, HeadEls} from './utils';
import type {BackdropKey} from '@arc/hub-model';
import type {ComponentProps} from 'react';

import {css} from '@linaria/core';

import {cram} from '@arc/rambo';

import {useRouteCapture} from 'modules/Router';

import {getColorPair, clamp} from './utils';

// types

type TitleProps = ComponentProps<HeadEls> & {
  as: HeadEls;
  align?: AlignVal;
  color?: BackdropKey;
  children: string;
  fontSize: string;
  lineHeight: string;
  sans?: boolean;
};

type Props = ComponentProps<HeadEls> & {
  as?: HeadEls;
  align?: AlignVal;
  color?: BackdropKey;
  children: string;
  sans?: boolean;
};

// fns

export const getAlign = (children?: string, limit = 50) => {
  if (!children) {
    return undefined;
  }

  return children.length < limit ? 'center' : 'left';
};

// component

function Title(props: TitleProps) {
  const {
    children, className, style,
    align, fontSize, lineHeight, sans,
    as: El, color = 'white',
    ...rest
  } = props;
  const ref = useRouteCapture();

  return (
    <El
      {...rest}
      ref={ref as any}
      className={cram(
        titleCls,
        sans ? sansCls : serifCls,
        className,
      )}
      dangerouslySetInnerHTML={{__html: children}}
      style={{
        ...style,
        '--align': align ?? getAlign(children),
        '--size': fontSize,
        '--line': lineHeight,
        '--color': `var(--${getColorPair(color)}-35)`,
      }} />
  );
}

export function Title1(props: Props) {
  return (
    <Title
      as="h1"
      {...props}
      fontSize={clamp(2.5, 4, 26.99875, 63.99875)}
      lineHeight={clamp(3, 4.5, 26.99875, 63.99875)} />
  );
}

export function Title2(props: Props) {
  return (
    <Title
      as="h2"
      {...props}
      fontSize={clamp(2, 3.5, 26.99875, 63.99875)}
      lineHeight={clamp(2.5, 4, 26.99875, 63.99875)} />
  );
}

export function Title3(props: Props) {
  return (
    <Title
      as="h3"
      {...props}
      fontSize={clamp(1.5, 3, 26.99875, 63.99875)}
      lineHeight={clamp(2, 3.5, 26.99875, 63.99875)} />
  );
}

export function Title4(props: Props) {
  return (
    <Title
      as="h4"
      {...props}
      fontSize={clamp(1.375, 2.5, 26.99875, 63.99875)}
      lineHeight={clamp(1.75, 3, 26.99875, 63.99875)} />
  );
}

export function Title5(props: Props) {
  return (
    <Title
      as="h5"
      {...props}
      fontSize="1.5rem"
      lineHeight="2rem" />
  );
}

// styles

const titleCls = css`
margin: 0;
font-size: var(--size);
line-height: var(--line);
text-align: var(--align);

em { font-style: italic; }
a { 
  color: var(--color);
  transition: filter 100ms ease-in-out;
  &:hover { filter: brightness(150%) saturate(75%); }
}
`;

const serifCls = css`
font-family: var(--font-head);
font-weight: 500;
letter-spacing: normal;
strong { font-weight: 800; }
`;

const sansCls = css`
font-family: var(--font-body);
font-weight: 700;
letter-spacing: -0.03125em;
word-spacing: 0.0625em;
text-transform: uppercase;
strong { font-weight: 900; }
`;
