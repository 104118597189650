// import

import type {BlockProps} from '../styles/Block';

import {css} from '@linaria/core';

import {Image} from 'modules/Media';

import {Block, pickStyleProps} from '../styles/Block';
import {Blurb} from '../styles/Blurb';
import {Title4} from '../styles/Title';

// types

type Props = BlockProps<'pullQuote'>;

// component

export function PullQuoteBlock(props: Props) {
  const {backdrop, blurbMark, speakerMark, imageMeta} = props;

  return (
    <Block {...pickStyleProps(props)} innerClassName={innerCls}>
      <Title4
        align="center"
        as={'blockquote' as 'h1'}
        className={quoteCls}
        color={backdrop}>
        {blurbMark}
      </Title4>

      {imageMeta || speakerMark ? (
        <cite className={speakerCls}>
          {speakerMark ? (
            <Blurb
              align="center"
              className={nameCls}
              color={backdrop}>
              {speakerMark.slice(3, -4)}
            </Blurb>
          ) : null}

          {imageMeta ? (
            <div className={imageCls}>
              <Image
                height={imageMeta.height}
                layout="intrinsic"
                objectFit="contain"
                objectPosition="center"
                src={imageMeta.url}
                width={imageMeta.width} />
            </div>
          ) : null}
        </cite>
      ) : null}
    </Block>
  );
}

// styles

const innerCls = css`
display: flex;
flex-flow: column;
align-items: center;
justify-content: center;
gap: 1.5rem;
max-width: var(--bp-m);
`;

const quoteCls = css`
display: flex;
flex-flow: column;
gap: 1.5rem;
margin-inline: min(1vw, var(--size));
margin-top: 1.5rem;
padding-inline: min(1vw, 1rem);
position: relative;

&::before, &::after {
  position: absolute;
  width: var(--size);
  height: var(--size);
  line-height: var(--size);
  font-size: calc(var(--size) * 3);
  color: var(--color);
  top: calc(var(--size) * 0.5);
}

&::before {
  content: '“';
  left: calc(var(--size) * -1);
}

&::after {
  content: '”';
  right: calc(var(--size) * -1);
}

> * { margin: 0; }
`;

const speakerCls = css`
display: flex;
gap: 1rem;
align-items: center;
justify-content: center;
`;

const imageCls = css`
flex: 0 1 auto;
max-width: 2rem;
aspect-ratio: 1;
overflow: hidden;
box-shadow: 0 0 0.5rem var(--black-25);
border-radius: 100rem;
`;

const nameCls = css`
flex: 1 0 auto;

&::before { content: '— '; }
`;
