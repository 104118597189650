// import

import type {AlignVal} from './utils';
import type {BackdropKey} from '@arc/hub-model';
import type {ComponentProps, ElementType} from 'react';

import {css} from '@linaria/core';

import {cram} from '@arc/rambo';

import {TypeCapture} from 'modules/Type';

import {getColorPair, getFlexAlign} from './utils';

// types

type Props = ComponentProps<any> & {
  as?: ElementType;
  align?: AlignVal;
  children: string;
  trim?: boolean | 'end' | 'top';
  serif?: boolean;
  color?: BackdropKey;
  small?: boolean;
};

// component

export function Blurb(props: Props) {
  const {
    children, className, style, small,
    align = 'left', serif, color = 'white',
    ...rest
  } = props;

  return (
    <TypeCapture
      {...rest}
      className={cram(blurbCls, {
        [serifCls]: serif,
        [smallCls]: small,
      }, className)}
      dangerouslySetInnerHTML={{__html: children}}
      style={{
        ...style,
        '--color': `var(--${getColorPair(color)}-35)`,
        '--flex': getFlexAlign(align),
        '--align': align ?? 'revert',
      }} />
  );
}

// styles

const blurbCls = css`
max-width: var(--bp-s);
align-self: var(--flex);

> * { 
  text-align: var(--align); 
  word-wrap: none;
  hyphens: manual;
}

li::before { color: var(--color); }

a { 
  color: var(--color) !important;
  text-decoration: underline var(--color) !important;
  transition: filter 100ms ease-in-out !important;
  &:hover { filter: brightness(150%) saturate(75%); }
}
`;

const serifCls = css`
font-family: var(--font-head);
font-weight: 400;
font-variation-settings: 'wght' 450;
letter-spacing: normal;
`;

const smallCls = css`
> * {
  font-size: 1rem;
  line-height: 1.375rem;
  letter-spacing: calc(-1em / 48);
}
`;
