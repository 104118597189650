// import

import type {BackdropKey} from '@arc/hub-model';
import type {ComponentProps} from 'react';

import {css} from '@linaria/core';
import {darken, desaturate} from 'polished';
import {useMemo} from 'react';

import {cram} from '@arc/rambo';

import {ZigZag} from 'modules/Svg';
import {useTheme} from 'modules/Theme';

import {getBackdrop, getBackdropBits} from './utils';

// types

type Props = ComponentProps<'div'> & {
  colorAbove: BackdropKey;
  colorBelow: BackdropKey;
  layout: 'lines' | 'none' | 'waves' | 'zigzag';
};

// fns

export const getDividerHeight = (layout: Props['layout']) => {
  switch (layout) {
    case 'zigzag': return '3.5rem';
    case 'waves': return '3.5rem';
    case 'lines': return '1.5rem';
    case 'none': return '0';
    // no default
  }
};

export const useDividerColors = (
  above: BackdropKey,
  below: BackdropKey,
  flip = false,
): [string, string, string, string, string] => {
  const theme = useTheme();

  return useMemo(() => {
    const aboveBits = getBackdropBits(above);
    const belowBits = getBackdropBits(below);
    const aboveColor = aboveBits[1] ?
      theme.palette[above][aboveBits[1]] :
      theme.color[above];
    const belowColor = belowBits[1] ?
      theme.palette[below][belowBits[1]] :
      theme.color[below];

    if (flip) {
      return [
        belowColor,
        desaturate(0, darken(0.1, belowColor)),
        desaturate(0.25, darken(0.2, belowColor)),
        desaturate(0.5, darken(0.3, belowColor)),
        aboveColor,
      ];
    }

    return [
      aboveColor,
      desaturate(0, darken(0.1, aboveColor)),
      desaturate(0.25, darken(0.2, aboveColor)),
      desaturate(0.5, darken(0.3, aboveColor)),
      belowColor,
    ];
  }, [above, below, theme]);
};

// component

export function Divider(props: Props) {
  const {layout} = props;

  switch (layout) {
    case 'zigzag': return <ZigZagDiv {...props} />;
    case 'waves': return <ZigZagDiv {...props} />;
    case 'lines': return <LinesDiv {...props} />;
    default: return null;
  }
}

function ZigZagDiv(props: Props) {
  const {children, className, layout, colorAbove, colorBelow, ...rest} = props;
  const flip = colorAbove === 'white' && colorBelow !== 'white';
  const colors = useDividerColors(colorAbove, colorBelow, flip);

  return (
    <div
      {...rest}
      className={cram(svgCls, className)}
      style={{'--backdrop': getBackdrop(colorBelow)}}>
      <ZigZag
        colors={colors}
        flip={!flip}
        id={`svg-${rest.id}`}
        layout={layout as any} />
    </div>
  );
}

function LinesDiv(props: Props) {
  const {children, className, layout, colorAbove, colorBelow, ...rest} = props;
  const flip = colorAbove === 'white' && colorBelow !== 'white';
  const colors = useDividerColors(colorAbove, colorBelow, flip);

  return (
    <div
      {...rest}
      className={cram(linesCls, {[flipCls]: flip}, className)}
      style={{
        '--c0': colors?.[0],
        '--c1': colors?.[1],
        '--c2': colors?.[2],
        '--c3': colors?.[3],
        '--c4': colors?.[4],
      }}>
      <div className="c1" />
      <div className="c2" />
      <div className="c3" />
    </div>
  );
}

// styles

const svgCls = css`
background: var(--backdrop);
`;

const linesCls = css`
display: flex;
flex-flow: column;
height: 1.5rem;

.c0 { background: var(--c0); flex: 5 1 auto; }
.c1 { background: var(--c1); flex: 4 1 auto; }
.c2 { background: var(--c2); flex: 3 1 auto; }
.c3 { background: var(--c3); flex: 2 1 auto; }
.c4 { background: var(--c4); flex: 1 1 auto; }
`;

const flipCls = css`
transform: rotate(180deg);
`;
