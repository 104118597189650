// import

import type {ColorKey} from 'modules/Theme';
import type {ComponentProps, JSXElementConstructor, PropsWithChildren} from 'react';

import {css} from '@linaria/core';

import {cram} from '@arc/rambo';

// types

type Props = ComponentProps<'div'> & PropsWithChildren<{
  color: ColorKey | string;
  size?: number;

  as?: JSXElementConstructor<any> | keyof JSX.IntrinsicElements;
}>;

// vars

const alphaRx = /^[a-z]+$/u;

// component

export function MarqueeBrackets(props: Props) {
  const {
    color, style, children, className,
    size = 0.75, as: El = 'div',
    ...rest
  } = props;

  return (
    <El
      {...rest as ComponentProps<typeof El>}
      className={cram(itemCls, className)}
      style={{
        ...style,
        '--c': alphaRx.test(color) ? `var(--${color})` : color,
        '--h': `${size}rem`,
        '--r': `${size * 4 / 3}rem`,
        '--b': `${size / 2}rem`,
      }}>
      <div className={bracketCls}>
        <div /> <div /> <div />
      </div>

      <div className={bodyCls}>
        {children}
      </div>

      <div className={cram(bracketCls, flipCls)}>
        <div /> <div /> <div />
      </div>
    </El>
  );
}

// styles

const itemCls = css`
display: flex;
flex-flow: column;
`;

const bodyCls = css`
padding: 0.5rem 0;
flex: 1 0 auto;
display: flex;
justify-content: center;
align-items: stretch;

li::before {
  color: var(--c) !important;
}
`;

const flipCls = css`
transform: rotate(180deg);
`;

const bracketCls = css`
div { position: relative; }

div, div::before, div::after {
  height: var(--h);
  box-sizing: content-box;
}

div::before,
div::after {
  content: "";
  display: block;
  position: absolute;
  border-style: solid;
  border-color: var(--c);
  border-width: 0;
}

div::before {
  border-width: var(--b) 0 0 var(--b);
  border-top-left-radius: var(--r);
}

div::after {
  border-width: var(--b) var(--b) 0 0;
  border-top-right-radius: var(--r);
}

div:nth-child(1) {
  &::before, &::after { width: 25%; }
  &::before { left: 25%; }
  &::after { right: 25%; }
}

div:nth-child(2) {
  &::before, &::after { width: 12.5%; }
  &::before { left: 12.5%; }
  &::after { right: 12.5%; }
}

div:nth-child(3) {
  &::before, &::after { width: 12.5%; }
  &::before { left: 0; }
  &::after { right: 0; }
}
`;
