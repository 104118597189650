// import

import type {BackdropKey} from '@arc/hub-model';
import type {ComponentProps} from 'react';

import {faLightbulbOn} from '@fortawesome/pro-regular-svg-icons';
import {css} from '@linaria/core';

import {cram} from '@arc/rambo';

import {Icon} from 'modules/Icon';

import {clamp, getBackdrop, getColorPair} from './utils';

// types

type Props = ComponentProps<'div'> & {
  backdrop: BackdropKey;
  firstInRegion: boolean;
};

// component

export function Marquee(props: Props) {
  const {children, className, backdrop, firstInRegion} = props;
  const color = backdrop === 'white' ? 'yellow' : getColorPair(backdrop);

  return (
    <section
      className={cram(blockCls, {
        [mergeAboveCls]: !firstInRegion,
      }, className)}
      style={{
        '--backdrop': getBackdrop(backdrop),
        '--block': clamp(4.5, 7, 35.99875, 63.99875),
        '--c0': getBackdrop(color),
        '--c1': `var(--${color}-80)`,
        '--c2': `var(--${color}-70)`,
        '--top': clamp(1.5, 4, 35.99875, 62.99875),
        '--lip': clamp(1.5, 3, 53.99875, 62.99875),
      }}>
      <div className={plaqueCls}>
        <div>
          <div className={cram(lipCls, lip1Cls)} />
          <div className={cram(lipCls, lip2Cls)} />
          <div className={cram(lipCls, lip3Cls)} />
          <Icon className={iconCls} icon={faLightbulbOn} />
        </div>

        <div className={contentCls}>
          {children}
        </div>
      </div>
    </section>
  );
}

// styles

const blockCls = css`
background-color: var(--backdrop);
scroll-margin-top: calc(var(--layout-headerHeight) + (var(--block) / 2));
padding-block: var(--block);
`;

const mergeAboveCls = css`
padding-top: 0;
`;

const plaqueCls = css`
position: relative;
z-index: 1;
filter:
  drop-shadow(0 0.25rem 0 var(--c1))
  drop-shadow(0 -0.25rem 0 var(--c1))
  drop-shadow(0 0.25rem 0 var(--c2))
  drop-shadow(0 -0.25rem 0 var(--c2));
`;

const lipCls = css`
background-color: var(--c0);
border-radius: 100rem 100rem 0 0;
margin: 0 auto;
`;

const lip1Cls = css`
width: 25%;
padding-top: 12.5%;
`;

const lip2Cls = css`
width: 75%;
padding-top: var(--lip);
`;

const lip3Cls = css`
width: 87.5%;
padding-top: var(--lip);

@media (max-width: 35.99875em) {
  display: none;
}
`;

const iconCls = css`
height: 12vw;
width: 12vw;
position: absolute;
top: var(--top);
left: calc(50% - 6vw);
color: var(--black);
filter:
  drop-shadow(0 0.125rem 0 var(--c1))
  drop-shadow(0 0.125rem 0 var(--c2));
`;

const contentCls = css`
background-color: var(--c0);
padding: 1.5rem 1.5rem 3rem;
text-align: center;
`;
