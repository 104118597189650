// import

import type {ComponentProps, PropsWithChildren} from 'react';

import {css} from '@linaria/core';
import * as R from 'ramda';
import {useMemo} from 'react';

import {cram} from '@arc/rambo';

import {Link} from 'modules/Link';
import {Type} from 'modules/Type';

import {MarqueeBrackets} from './MarqueeBrackets';

// types

type Heading = {
  id: string;
  level: number;
  text: string;
  children?: Heading[];
};

type Limits = {min?: number; max?: number};

type Props = ComponentProps<typeof MarqueeBrackets> & PropsWithChildren<{
  min?: number;
  max?: number;
  headings?: Heading[];
}>;

// fns

const pruners = [
  R.reject(R.propEq('level', 6)),
  R.reject(R.propEq('level', 5)),
  R.reject(R.propEq('level', 4)),
  R.reject(R.propEq('level', 3)),
  R.reject(R.propEq('level', 2)),
];

const pruneHeadings = (
  {min = 0, max = Infinity}: Limits,
  heads: Heading[],
) => {
  return R.reduce((res, fn) => {
    const out = fn(res);

    return out.length <= max ? R.reduced(out.length < min ? [] : out) :
      out.length < min ? R.reduced(res.length < min ? [] : res) : out;
  }, heads, pruners);
};

const deepPush = (last: Heading, next: Heading) =>
  R.over(
    R.lensPath(R.times(R.always('children'), next.level - last.level)),
    R.append(next),
    last,
  );

const linkReducer = (acc: Heading[], next: Heading) => {
  const last = R.last(acc);
  next = {...next, children: []};

  return last && last.level < next.level ?
    [...R.init(acc), deepPush(last, next)] :
    [...acc, next];
};

function mapLinks(links: Heading[]) {
  return links.length ? (
    <ol className="linkList">
      {links.map(({id, text, children = []}) => (
        <li key={id}>
          <Link
            className={cram('link', 'red')}
            dangerouslySetInnerHTML={{__html: text}}
            href={`#${id}`}
            tabIndex={-1} />

          {mapLinks(children)}
        </li>
      ))}
    </ol>
  ) : null;
}

const parseHeadings = (
  limits: Limits,
  heads: Heading[],
) => {
  return mapLinks(pruneHeadings(limits, heads).reduce(linkReducer, []));
};

// hook

export function useTOC(
  headings: Heading[],
  {min, max, ...props}: Partial<Props>,
) {
  return useMemo(() => {
    const links = parseHeadings({min, max}, headings);

    return links ? (
      <TableOfContents color="blue" {...props} as="nav">
        {links}
      </TableOfContents>
    ) : null;
  }, [headings, min, max]);
}

// component

export function TableOfContents(props: Props) {
  const {headings = [], max, min, children, ...rest} = props;
  const links = children || parseHeadings({min, max}, headings);

  if (!links) {
    return null;
  }

  return (
    <MarqueeBrackets {...rest}>
      <div className={bodyCls}>
        <div className="name">Table of Contents</div>
        <Type className="type">
          {links}
        </Type>
      </div>
    </MarqueeBrackets>
  );
}

// styles

const bodyCls = css`
display: flex;
flex-flow: column nowrap;
justify-content: center;
align-items: center;

.name {
  margin-top: 0.5rem;
  font-weight: 500;
  text-align: center;
  font-size: 1.375rem;
  font-family: var(--font-head);
}

.type {
  padding: 0 0.5rem 0.75rem 0;
  line-height: 1.5rem;

  ol { font-size: 1rem; }
}
`;
