// import

import type {BackdropKey} from '@arc/hub-model';

import * as R from 'ramda';

// types

export type AlignVal = 'center' | 'left' | 'right';
export type DisplayVal = 'border' | 'solid' | 'text';

export type HeadEls = typeof headEls[number];

// vars

const aligns: Record<AlignVal, string> = {
  left: 'flex-start',
  right: 'flex-end',
  center: 'center',
};

const toBack: Record<BackdropKey, [string, number?]> = {
  red: ['red', 90],
  orange: ['orange', 90],
  yellow: ['yellow', 90],
  green: ['green', 85],
  blue: ['blue', 95],
  purple: ['purple', 90],
  white: ['white'],
};

const colorPair: Record<BackdropKey, BackdropKey> = {
  red: 'blue',
  orange: 'green',
  yellow: 'blue',
  green: 'purple',
  blue: 'red',
  purple: 'blue',
  white: 'blue',
};

const headEls = ['div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as const;

// fns

export const getColorPair = (color: BackdropKey) => {
  return colorPair[color];
};

export const getFlexAlign = (align: AlignVal = 'center') => {
  return aligns[align];
};

export const getBackdropBits = (color: BackdropKey) => {
  return toBack[color];
};

export const getBackdrop = (color: BackdropKey) => {
  return `var(--${toBack[color].join('-')})`;
};

export const getHeadEls = (
  props: {eyebrow?: string; firstOfType?: boolean},
  inc = 0,
) => {
  const offset = R.sum([
    1,
    inc,
    Number(!!props.eyebrow),
    Number(props.firstOfType) * -1,
  ]);
  const eyebrow = headEls[offset];
  const title = headEls[offset + 1];
  const subhead = headEls[offset + 2];

  return {eyebrow, title, subhead};
};

export const clamp = (
  minSizeRem: number,
  maxSizeRem: number,
  minWidthRem: number,
  maxWidthRem: number,
) => {
  const slope = (maxSizeRem - minSizeRem) / (maxWidthRem - minWidthRem);
  const cross = (-minWidthRem * slope) + minSizeRem;

  return `clamp(${minSizeRem}rem, ${cross}rem + ${slope * 100}vw, ${maxSizeRem}rem)`;
};
