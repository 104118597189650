// import

import type {AlignVal} from './utils';
import type {BackdropKey} from '@arc/hub-model';
import type {ReactNode} from 'react';

import {css} from '@linaria/core';

import {cram} from '@arc/rambo';

import {Blurb} from './Blurb';
import {Eyebrow} from './Eyebrow';
import {Title1, Title2, Title3, Title4, Title5} from './Title';
import {getHeadEls} from './utils';

// types

type Props = {
  className?: string;
  children?: ReactNode;

  eyebrow?: string;
  title?: string;
  blurbMark?: string;

  inc?: 0 | 1 | 2;
  viewInc?: 0 | 1 | 2 | 3;
  align?: AlignVal;
  backdrop: BackdropKey;
  firstOfType: boolean;
  layout?: 'bold' | 'none' | 'subhead';
  small?: boolean;
};

// vars

const incs = [
  [Title1, Title2],
  [Title2, Title3],
  [Title3, Title4],
  [Title4, Title5],
  [Title5, Title5],
] as const;

// component

export function TextFields(props: Props) {
  const {className, eyebrow, viewInc, inc = 0, title, blurbMark, align, firstOfType, backdrop, layout, children, small} = props;
  const hnums = getHeadEls(props, inc);
  const [T1, T2] = incs[(viewInc ?? inc) + (layout === 'subhead' ? 2 : 0)];
  const Title = firstOfType ? T1 : T2;

  return eyebrow || title || blurbMark || children ? (
    <div className={cram(blockCls, {[smallCls]: small}, className)}>
      {eyebrow || title ? (
        <div>
          {eyebrow ? (
            <Eyebrow align={align} as={hnums.eyebrow}>
              {eyebrow}
            </Eyebrow>
          ) : null}

          {title ? (
            <Title
              align={align}
              as={hnums.title}
              className={cram({[italicCls]: layout === 'subhead'})}
              color={backdrop}
              sans={layout === 'bold'}>
              {title}
            </Title>
          ) : null}
        </div>
      ) : null}

      {blurbMark ? (
        <Blurb
          align={align}
          color={backdrop}
          serif={layout === 'bold'}
          small={small}>
          {blurbMark}
        </Blurb>
      ) : null}

      {children}
    </div>
  ) : null;
}

// styles

const blockCls = css`
display: flex;
flex-flow: column;
gap: 1.5rem;
`;

const smallCls = css`
gap: 1rem;
`;

const italicCls = css`
font-style: italic;
`;
