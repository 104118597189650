// import

import type {MainNode, PageNode} from '@arc/hub-model';

import {ArticleJsonLd, NextSeo, BreadcrumbJsonLd} from 'next-seo';
import {memo} from 'react';

import {isStr} from '@arc/rambo';

import {siteUrl} from 'libs/constants';
import {useSettings} from 'modules/App';

import {getCreateDate, getUpdateDate} from '../Seo/utils';

// types

type Props = {
  page: MainNode | PageNode;
};

// component

// eslint-disable-next-line prefer-arrow-callback
export const PageSeo = memo(function PageSeo(props: Props) {
  const {page} = props;
  const {seo, biz, openGraphLogo} = useSettings();

  const url = siteUrl + page.meta.link;
  const title = page.meta.seoTitle?.trim() ||
    `${page.meta.title} ${seo.sep} ${seo.title}`;
  const description = page.meta.seoDescription;
  const publishedTime = getCreateDate(page);
  const modifiedTime = getUpdateDate(page);

  const images = [
    page.meta.bannerImage ??
    openGraphLogo.url,
  ].filter(isStr);

  return (
    <>
      <NextSeo
        canonical={url}
        description={description.replace(/"/gu, '`')}
        openGraph={{
          url,
          title,
          description,
          type: 'article',
          site_name: seo.title,
          images: images.map((img) => ({url: img})),
          article: {
            publishedTime,
            modifiedTime,
          },
        }}
        title={title} />

      <ArticleJsonLd
        authorName={[biz.feedAuthor.name]}
        dateModified={modifiedTime}
        datePublished={publishedTime}
        description={page.meta.seoDescription.replace(/"/gu, '`')}
        images={images}
        publisherLogo={biz.logoImage}
        publisherName={biz.feedAuthor.name}
        title={page.meta.seoTitle?.trim() || page.meta.title}
        url={siteUrl + page.meta.link} />

      <BreadcrumbJsonLd
        itemListElements={[
          {
            position: 1,
            name: page.meta.title,
            item: url,
          },
        ]} />
    </>
  );
});
