// import

import type {AlignVal} from './utils';
import type {ComponentProps} from 'react';

import {css} from '@linaria/core';

import {cram, isStr} from '@arc/rambo';

import {getAlign} from './Title';
import {getFlexAlign} from './utils';

// types

type Props = ComponentProps<'div'> & {
  align?: AlignVal;
  toAlign?: string;
};

// component

export function Aligner(props: Props) {
  const {children, style, className, align, toAlign, ...rest} = props;
  const alignThis = isStr(children) ? children : toAlign;

  return (
    <div
      {...rest}
      className={cram(alignCls, className)}
      style={{
        ...style,
        '--align': getFlexAlign(align ?? getAlign(alignThis)),
      }}>
      {children}
    </div>
  );
}

// styles

const alignCls = css`
display: flex;
flex-flow: wrap;
align-items: center;
justify-content: var(--align);
gap: 1.5rem;

> * {
  flex: 0 1 auto;
  display: block;
  text-align: center;
}
`;
