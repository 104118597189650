// import

import type {BlockProps} from '../styles/Block';

import {css} from '@linaria/core';

import {useMediaMatch} from '@arc/react-hooks';

import {useTOC} from 'modules/Solo';
import {TypeCapture} from 'modules/Type';

import {Block, pickStyleProps} from '../styles/Block';

// types

type Props = BlockProps<'richText'>;

// component

export function RichTextBlock(props: Props) {
  const {blurbMark, headings, options = [], backdrop} = props;
  const isTall = useMediaMatch('(min-height: 63em)');
  const color = backdrop === 'white' ? 'blue' : `var(--${backdrop}-35)`;
  const bodyToc = useTOC(headings, {color, min: 3, max: 50});
  const sideToc = useTOC(headings, {color, min: 3, max: isTall ? 25 : 15});

  return (
    <Block
      {...pickStyleProps(props)}
      className={blockCls}
      innerClassName={innerCls}>
      <div className={contentCls}>
        {options.includes('toc') ? (
          <div className={bodyTocCls}>
            {bodyToc}
          </div>
        ) : null}

        <TypeCapture html={blurbMark} />
      </div>

      {options.includes('toc') && !!sideToc ? (
        <aside className={sidebarCls}>
          {sideToc}
        </aside>
      ) : null}
    </Block>
  );
}

// styles

const blockCls = css`
position: relative;
`;

const innerCls = css`
display: flex;
flex-flow: row nowrap;
align-items: flex-start;
justify-content: center;
gap: 4.5rem;
`;

const contentCls = css`
flex: 1 1 auto;
max-width: var(--bp-sm);
`;

const sidebarCls = css`
flex: 1 0 16rem;
min-width: 16rem;
max-width: 21rem;
display: flex;
flex-flow: column;
gap: 3rem;
position: sticky;
top: calc(var(--layout-headerHeight) + 1.5rem);

@media (max-width: 62.99875em) {
  display: none;
  visibility: hidden;
}
`;

const bodyTocCls = css`
display: none;
visibility: hidden;
margin-bottom: 3rem;

@media (max-width: 62.99875em) {
  display: block;
  visibility: visible;
}
`;
