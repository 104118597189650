// import

import type {AlignVal, DisplayVal} from './utils';
import type {BackdropKey} from '@arc/hub-model';
import type {ComponentProps} from 'react';

import {ActionLink} from './ActionTarget';
import {Aligner} from './Aligner';

// types

type Props = ComponentProps<'div'> & {
  href: string;
  align?: AlignVal;
  display?: DisplayVal;
  children: string;
  color: BackdropKey;

  secondary?: {
    action?: string;
    link?: string;
  };
};

// fns

const getDisplay = (href: string, color?: BackdropKey) => {
  return href.startsWith('/') || href.startsWith('#') ? 'text' :
    href.includes('app.') ? 'solid' :
    color === 'white' ? 'border' :
    'solid';
};

// component

export function ClickAction(props: Props) {
  const {children, href, align, display, color, secondary, ...rest} = props;

  return (
    <Aligner {...rest} align={align} toAlign={children}>
      <ActionLink
        color={color}
        display={display ?? getDisplay(href, color)}
        href={href}>
        {children}
      </ActionLink>

      {secondary?.action && secondary?.link ? (
        <ActionLink
          color={color}
          display="text"
          href={secondary.link}>
          {secondary.action}
        </ActionLink>
      ) : null}
    </Aligner>
  );
}

