// import

import type {ComponentProps} from 'react';

import {css} from '@linaria/core';

import {cram} from '@arc/rambo';

// types

type Props = ComponentProps<'div'> & {
  color: string;
  align: 'above' | 'below' | 'left' | 'right';
};

// component

export function Viewport(props: Props) {
  const {children, className, color, style, align, ...rest} = props;

  return (
    <div
      {...rest}
      className={cram(blockCls, className)}
      style={{
        ...style,
        '--color': `var(--${color})`,
        '--border': `var(--${color}-20)`,
        '--shadow': ['left'].includes(align) ?
          '-0.5rem 0.5rem' : '0.5rem 0.5rem',
      }}>
      <div className={headCls}>
        <div className={dotCls} />
        <div className={dotCls} />
        <div className={dotCls} />
      </div>
      {children}
    </div>
  );
}

// styles

const blockCls = css`
overflow: hidden;
border-radius: 0.5rem;
border: var(--rpx) solid var(--border);
box-shadow: var(--shadow) var(--border);
`;

const headCls = css`
background: var(--color);
border-bottom: var(--rpx) solid var(--border);
padding: 0.75rem 0.75rem;
display: flex;
gap: 0.375rem;
justify-content: flex-start;
`;

const dotCls = css`
width: 0.875rem;
height: 0.875rem;
border-radius: 100rem;
background: var(--white-50);
border: var(--rpx) solid var(--border);
`;
