// import

import type {AlignVal, DisplayVal} from './utils';
import type {BackdropKey} from '@arc/hub-model';
import type {ComponentProps} from 'react';

import {css} from '@linaria/core';

import {useSignupForm} from 'modules/SignupForm';

import {ActionButton} from './ActionTarget';
import {Aligner} from './Aligner';
import {getColorPair} from './utils';

// types

type Props = ComponentProps<'div'> & {
  align?: AlignVal;
  display?: DisplayVal;
  children: string;
  color: BackdropKey;
  groups: string[];
};

// component

export function SignupAction(props: Props) {
  const {children, groups, align, display, color, ...rest} = props;
  const {onSubmit, inputProps, buttonProps, ref} = useSignupForm(groups);

  return (
    <Aligner {...rest} align={align}>
      <form ref={ref} className={formCls} onSubmit={onSubmit}>
        <input
          {...inputProps}
          className={inputCls}
          style={{'--button': `var(--${getColorPair(color)}-40)`}} />

        <ActionButton
          {...buttonProps}
          className={buttonCls}
          color={color}
          display="solid">
          {children}
        </ActionButton>
      </form>
    </Aligner>
  );
}

// styles

const formCls = css`
display: flex !important;
width: min(100%, 35rem);
`;

const inputCls = css`
all: unset;
background: var(--white);
border-width: var(--rpx) 0 var(--rpx) var(--rpx);
border-style: solid;
border-color: var(--button);
border-radius: 0.25rem 0 0 0.25rem;
padding: 0 1.5rem;
line-height: 3.5rem;
height: 3.5rem;
text-align: left;

flex: 1 1 auto;
font-size: 1.25rem;
width: 1px;
`;

const buttonCls = css`
border-top-left-radius: 0 !important;
border-bottom-left-radius: 0 !important;
flex: none;
`;
