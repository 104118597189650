// import

import type {BlockProps} from '../styles/Block';

import {css} from '@linaria/core';

import {cram} from '@arc/rambo';

import {ActionFields} from '../styles/ActionFields';
import {Block, pickStyleProps} from '../styles/Block';
import {MediaBlock} from '../styles/MediaBlock';
import {TextFields} from '../styles/TextFields';
import {clamp} from '../styles/utils';

// types

type Props = BlockProps<'collection'>;

// component

export function CollectionBlock(props: Props) {
  const {backdrop, items, layout, firstOfType} = props;
  const shortTitles = items.every((i) => (i.title?.length ?? 0) < 10);
  const border = backdrop === 'white' ?
    'var(--gray-75)' :
    `var(--${backdrop}-35)`;

  return (
    <Block
      {...pickStyleProps(props)}
      innerClassName={cram(listClasses[layout])}
      style={{
        '--border': border,
        '--gap': clamp(1.5, 3, 35.99875, 71.99875),
        '--cols': items.length <= 4 ?
          items.length : Math.ceil(items.length / 2),
      }}>
      {items.map(({layout: itemLayout, ...item}, key) => {
        const mediaEl = (
          <MediaBlock
            {...item}
            smallVideo
            align="right"
            backdrop={backdrop}
            layout={itemLayout} />
        );

        return (
          <div
            key={key}
            className={cram(itemClasses[layout], {
              [viewportCls]: itemLayout === 'viewport',
            })}>
            {mediaEl ? (
              <div
                className={cram(mediaCls, {
                  [viewportCls]: itemLayout === 'viewport',
                })}>
                {mediaEl}
              </div>
            ) : null}

            <TextFields
              {...item}
              align={layout === 'grid' ? 'center' : 'left'}
              backdrop={backdrop}
              className={textCls}
              firstOfType={firstOfType}
              inc={1}
              small={layout === 'grid'}
              viewInc={layout === 'list' || shortTitles ? 2 : 3}>
              <ActionFields
                {...item}
                align={layout === 'grid' ? 'center' : 'left'}
                backdrop={backdrop}
                display="text"
                small={layout === 'grid'} />
            </TextFields>
          </div>
        );
      })}
    </Block>
  );
}

// styles

const mediaCls = css`
flex: 0 2 content;
min-width: 9rem;
max-width: 18rem;
width: 100%;
display: flex;
align-items: center;
justify-content: center;

> * { display: block !important; }
`;

const textCls = css`
flex: 2 1 fill;
max-width: var(--bp-sm);
`;

const gridListCls = css`
max-width: var(--bp-l);
margin-inline: calc(var(--inline) / 2);
display: grid;
grid-auto-rows: auto;
grid-template-columns: repeat(var(--cols), 1fr);
gap: var(--gap);
justify-content: center;
justify-items: center;
align-items: start;

@media (max-width: 62.99875em) {
  grid-template-columns: repeat(min(var(--cols), 3), 1fr);
}

@media (max-width: 44.99875em) {
  grid-template-columns: repeat(min(var(--cols), 2), 1fr);
}

@media (max-width: 26.99875em) {
  grid-template-columns: repeat(1, 1fr);
}
`;

const gridItemCls = css`
display: flex;
flex-flow: column;
gap: 1.5rem;
align-items: center;
max-width: 18rem;

.${mediaCls} {
  padding-inline: 1.5rem;
}

@media (max-width: 62.99875em) {
  &:nth-child(n + 7) { display: none; }
}

@media (max-width: 44.99875em) {
  &:nth-child(n + 5) { display: none; }
}
`;

const listListCls = css`
max-width: var(--bp-m);
display: flex;
flex-flow: column;
`;

const listItemCls = css`
display: flex;
gap: 1.5rem;
align-items: center;
padding-block: 3rem;
width: 100%;

@media (max-width: 53.99875em) {
  flex-flow: wrap;
  justify-content: center;
}

&:nth-child(n + 2) {
  border-top: var(--rpx) solid var(--border);
}
`;

const listClasses = {
  grid: gridListCls,
  list: listListCls,
} as const;

const itemClasses = {
  grid: gridItemCls,
  list: listItemCls,
} as const;

const viewportCls = css`
max-width: 100%;
padding-inline: 0 !important;
`;
