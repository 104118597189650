// import

import type {AlignVal, DisplayVal} from './utils';
import type {BackdropKey} from '@arc/hub-model';

import {css} from '@linaria/core';

import {cram} from '@arc/rambo';

import {ClickAction} from './ClickAction';
import {SignupAction} from './SignupAction';

// types

type Props = {
  className?: string;

  action?: string;
  link?: string;
  groups?: string[];

  align?: AlignVal;
  display?: DisplayVal;
  backdrop: BackdropKey;
  small?: boolean;

  secondary?: {
    action?: string;
    link?: string;
  };
};

// component

export function ActionFields(props: Props) {
  const {
    action, link, groups, display, small,
    className, align, backdrop: color,
    secondary,
  } = props;
  const pass = {
    align,
    color,
    className: cram({[smallCls]: small}, className),
  };

  return action && groups?.length ? (
    <SignupAction {...pass} groups={groups}>
      {action}
    </SignupAction>
  ) : action && link ? (
    <ClickAction
      {...pass}
      display={display}
      href={link}
      secondary={secondary}>
      {action}
    </ClickAction>
  ) : null;
}

// styles

const smallCls = css`
font-size: 1rem;
line-height: 1.375rem;
letter-spacing: calc(-1em / 128);
`;
