// import

import type {BlockProps} from '../styles/Block';

import {css} from '@linaria/core';

import {Block, pickStyleProps} from '../styles/Block';
import {getColorPair} from '../styles/utils';

// types

type Props = BlockProps<'break'>;

// component

export function BreakBlock(props: Props) {
  const {layout, backdrop} = props;
  const color = backdrop === 'white' ? null : getColorPair(backdrop);

  return (
    <Block {...pickStyleProps(props)}>
      {layout === 'candy' ? (
        <hr
          className={candyCls}
          style={{
            '--c1': color ? `var(--${color}-35)` : 'var(--yellow)',
            '--c2': color ? `var(--${color}-60)` : 'var(--red)',
            '--c3': color ? `var(--${color}-85)` : 'var(--blue)',
            '--shadow': color ?
              `rgba(var(--rgb-${color}), 0.25)` :
              'var(--black-25)',
          }} />
      ) : null}
    </Block>
  );
}

// styles

export const candyCls = css`
border: none;
border-radius: 0.5rem;
height: 0.5rem;
max-width: max(27rem, 75%);
margin: 0 auto;
position: relative;
overflow: visible;
background-position: center;
background:
  repeating-linear-gradient(
    -30deg,
    var(--c1) 0,
    var(--c1) 0.5rem,
    var(--c2) 0.5rem,
    var(--c2) 1.0rem,
    var(--c3) 1.0rem,
    var(--c3) 1.5rem
  );
box-shadow:
  inset 0 0 0.25rem var(--shadow),
  0 0 0.25rem var(--shadow);
`;
