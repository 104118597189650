// import

import type {BackdropKey, BuilderBlock} from '@arc/hub-model';
import type {ComponentProps, FC} from 'react';

import {css} from '@linaria/core';
import * as R from 'ramda';

import {cram} from '@arc/rambo';

import {clamp, getBackdrop} from './utils';

// types

export type BlockType = BuilderBlock['type'];

export type BlockProps<T extends BlockType = BlockType> =
  Extract<BuilderBlock, {type: T}> & StyleProps;

export type BlockComponent = FC<Partial<StyleProps>>;

export type StyleProps = {
  id: string;
  innerClassName?: string;
  backdrop: BackdropKey;
  prevBackdrop?: BackdropKey;
  firstOfType: boolean;
  lastOfType: boolean;
  firstInRegion: boolean;
  lastInRegion: boolean;
};

// vars

const styleProps: Array<keyof StyleProps> = [
  'id',
  'backdrop',
  'firstOfType',
  'lastOfType',
  'firstInRegion',
  'lastInRegion',
];

// fns

export const pickStyleProps = (props: StyleProps): StyleProps => {
  return R.pick(styleProps, props);
};

// components

export function Block(props: ComponentProps<'section'> & StyleProps) {
  const {
    className, children, style,
    backdrop, innerClassName,
    firstOfType, lastOfType, firstInRegion, lastInRegion,
    ...rest
  } = props;

  return (
    <section
      {...rest}
      className={cram(blockCls, {
        [mergeAboveCls]: !firstInRegion,
      }, className)}
      style={{
        ...style,
        '--backdrop': getBackdrop(backdrop),
        '--block': clamp(4.5, 7, 35.99875, 63.99875),
        '--inline': clamp(1.5, 9, 26.99875, 63.99875),
      }}>
      <div className={cram(blockInnerCls, innerClassName)}>
        {children}
      </div>
    </section>
  );
}

// styles

const blockCls = css`
display: flex;
justify-content: center;
align-items: center;
background-color: var(--backdrop);
scroll-margin-top: calc(var(--layout-headerHeight) + (var(--block) / 2));
padding-block: var(--block);
`;

const mergeAboveCls = css`
padding-top: 0;
`;

const blockInnerCls = css`
flex: 1 1 auto;
margin-inline: var(--inline);
max-width: var(--bp-ml);
`;
